import React, { useRef, useEffect, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import AddRowStatusBar from '@icarius-common/editableGrid/Components/addRow/addRowButton';
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { getDistributionCostFromServer, setDistributionCostToServer } from "../actions";
import { getRows, getLoading, getSelects } from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import { openDialogAction } from "@icarius-common/dialog/actions";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const DistributionCost = ({ code, name, handleGoBack }) => {

  const [dataChanged, setDataChanged] = useState(false);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);

  const dispatch = useDispatch();
  const rowData = useSelector(getRows);
  const selects = useSelector(getSelects);
  const isLoading = useSelector(getLoading);

  const gridRef = useRef();

  useEffect(() => {
    dispatch(getDistributionCostFromServer(code));
    window.scrollTo(0, 0);
  }, [dispatch, code])

  useEffect(() => {
    if (gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef])

  const handleSave = () => {
    const rowData = gridReference.getVisibleRows().map(item => item.data);
    const formattedData = rowData.map(row => {
      let modifiedRow = { ...row };
      if (modifiedRow["Porcentaje"]) {
        modifiedRow["Porcentaje"] = modifiedRow["Porcentaje"].replace(/\./g, '').replace(/,/g, '.')
      }
      return {
        ...modifiedRow,
        employeeCode: code,
        percentage: Boolean(!modifiedRow["Porcentaje"] || modifiedRow["Porcentaje"] === "") ? "" : parseFloat(modifiedRow["Porcentaje"]),
      }
    });

    const sum = formattedData.reduce(
      (acc, obj) => acc + (obj.percentage || 0),
      0
    );
    if (sum !== 100) {
      dispatch(openSnackbarAction({ msg: "La sumatoria de los porcentajes debe ser 100%", severity: "warning" }));
    } else {
      dispatch(setDistributionCostToServer(formattedData))
        .then((resp) => {

          let msg = "Valores actualizados con éxito";
          if (resp.data.status === "OK_REPROCESS") {
            msg = "Ha modificado la distribución contable de costos y el colaborador existe en un proceso de cálculo actual, es necesario reprocesarlo"
          }

          dispatch(openSnackbarAction({ msg, severity: "success", duration: msg.includes("Valores") ? 5000 : 10000 }));

          //limpiar el seleccionado
          gridReference.deselectAll()

          setShouldReloadGrid(true);

          if (resp?.status === 200) {
            setDataChanged(false);
          }
        })
    }
  };

  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Desea eliminar las filas seleccionadas?',
      onConfirm: () => deleteRowsLocally(selectedRows),
    }));
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Valores eliminados. Presione guardar para hacer permanentes los cambios.', severity: "success", duration: 10000 }));
  }

  const handleCheckGoBack = () => {
    if (dataChanged) {
      setConfirmationDialogIsOpen(true);
    } else {
      handleGoBack();
    }
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Distribucion de costos');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.value) {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'Porcentaje' && gridCell.value !== "") {
              excelCell.value = parseFloat(String(gridCell.value).replace(/\./g, '').replace(/,/g, '.'));
              excelCell.numFmt = '_(#,##0.000_);_((#,##0.000)';
            }
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Distribucion de costos.xlsx');
      });
    });
  }

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={handleSave}
      type={"save"}
    />
  )

  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const addRowButton = () => (
    <AddRowStatusBar {...gridRef.current} />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCheckGoBack}
      type={"goBack"}
    />
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  const selectDataSources = (name) => {
    switch (name) {
      case "Proyecto":
        return selects.projects || [];
      case "Centro de costo":
      case "Dimensión 2":
      case "Dimensión 3":
      case "Dimensión 4":
      case "Dimensión 5":
        return selects.benefitCenters || [];
      default:
        return [];
    }
  }

  const formattedData = rowData.map(row => { return { ...row, Porcentaje: formatNumberOrReturnUndefined(row["Porcentaje"], 0, 3) } })

  useEffect(() => {
    rowData && setShouldReloadGrid(true);
  }, [rowData])
  return (
    <CommonPage
      title={`Distribución de costos - ${name}`}
      menuItems={[goBackButton, addRowButton, saveItem, exportGrid, deleteItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <EditableGridv2
        selectDataSources={selectDataSources}
        setShouldReloadGrid={setShouldReloadGrid}
        shouldReloadGrid={shouldReloadGrid}
        setDataChanged={setDataChanged}
        ref={gridRef}
        gridName="distributionCost"
        rowData={formattedData}
        columns={getColumnDefByPage(paths.distributionCost)}
      />
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
    </CommonPage>
  );
}

export default DistributionCost;
