import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getDistributionCostApi,
    saveDistributionCostApi
} from "@icarius-connection/api";

export const getDistributionCostFromServer = (code) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getDistributionCostApi(`?employeeCode=${code}`);
        let rows = response.data && response.data.data;
        let selects = response.data && response.data.selects;

        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows, selects },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setDistributionCostToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ROWS });
    try {
        let response = await saveDistributionCostApi(data);
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.SET_ROWS_FULFILLED,
            payload: { rows },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.SET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};