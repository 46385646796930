import paths from "@icarius-localization/paths";
import {
	numberFormatterMin0Max3,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.distributionCost],
        config: {
            headerName: "Centro de costo",
            field: "Centro de costo",
            isObligatory: false,
            isSelect: true,
        }
    },
    {
        pages: [paths.distributionCost],
        config: {
            headerName: "Proyecto",
            field: "Proyecto",
            isObligatory: false,
            isSelect: true,
        }
    },
    {
        pages: [paths.distributionCost],
        config: {
            headerName: "Dimensión 2",
            field: "Dimensión 2",
            isObligatory: false,
            isSelect: true,
        }
    },
    {
        pages: [paths.distributionCost],
        config: {
            headerName: "Dimensión 3",
            field: "Dimensión 3",
            isObligatory: false,
            isSelect: true,
        }
    },
    {
        pages: [paths.distributionCost],
        config: {
            headerName: "Dimensión 4",
            field: "Dimensión 4",
            isObligatory: false,
            isSelect: true,
        }
    },
    {
        pages: [paths.distributionCost],
        config: {
            headerName: "Dimensión 5",
            field: "Dimensión 5",
            isObligatory: false,
            isSelect: true,
        }
    },
    {
        pages: [paths.distributionCost],
        config: {
            headerName: "Porcentaje",
            field: "Porcentaje",
            filterParams: numberFilterParams,
            valueFormatter: numberFormatterMin0Max3,
            isNumeric: false,
            isObligatory: true,
            maxLength: 9,
            minLength: 1,
            validationUnrequired: true,
            isQuantityEntryPerConceptSet: true
        }
    },
]